<template>
  <div class="location" id="location">
    <div class="location-container">
        <div class="info">
           <p>eMaker Solutions 易代工网络科技成立于2019年，是一家由全球隐形冠军代表、工业4.0核心参与者和推进者－德国菲尼克斯和江苏经信智能制造研究院联合战略投资、聚焦工业制造领域的产业数字化服务商。</p>
           <p>平台汇聚制造、工程、运营、人力资源、IT互联网等多领域专家，基于数字中台技术，为中小工业企业提供适用、好用、实惠的一站式数字化解决方案和工业配套服务，帮助企业通过实施全面数智化管理，解决增长、交付、成本、品质、服务、人效、管理协同等核心关键问题，实现企业的质量、效率和动力变革，助力成为专精特新小巨人企业，开创事业发展新格局。</p>
        </div>
        <div class="img-wrap">
            <img src="@/assets/images/contact-us-location.png" alt="" />
        </div>
        <div class="cantact" >
            <el-row :gutter="20">
                <el-col :span="12">
                    <i></i>
                    <div>
                        <p class="title">公司邮箱</p>
                        <p class="content">service@emakercn.com</p>
                    </div>
                </el-col >
                <el-col :span="12">
                    <i></i>
                    <div>
                        <p class="title">联系电话</p>
                        <p class="content">025-66045736</p>
                    </div> 
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <i></i>
                    <div>
                        <p class="title">公司地址</p>
                        <p class="content">江苏省南京市江宁开发区苏源大道19号B4座</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <i></i>
                    <div>
                        <p class="title">邮政编码</p>
                        <p class="content">210005</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>
 .location {
    padding: 20px 20px;
 }

 .location .info  {
    font-size: 14px;
    line-height: 1.8;
 }

 .location .info p + p {
    margin-top: 16px;
 }

 .location .img-wrap {
    width: 100%;
    margin-top: 20px;
 }

 .location .img-wrap img {
    width: 100%;
 }

 .location .cantact {
    margin-top: 16px;
 }

 .location .cantact > .el-row {
    margin-top: 18px;
 }

 .el-row .el-col {
    display: flex;
    align-items: stretch;
 }

 .el-row .el-col > div {
    flex: 1;
    min-width: 0;
    white-space: pre-wrap;
    word-break: break-all;
 }

 .el-row .el-col > div .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
 }

 .el-row .el-col > div .content {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
 }

</style>