<template>
  <div class="m-about">
    <Location />
  </div>
</template>

<script>
import Location from './location/index.vue'
export default {
  name:'About',
  components: { Location }
}
</script>

<style scoped>
 .m-about {
    min-height: calc(100vh - 246px);
 }
</style>